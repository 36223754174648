import React from 'react';

import {useDispatch, useSelector} from "react-redux";
import LineChartSvg from "assets/images/chart/Line.svg";
import AreaChartSvg from "assets/images/chart/Area.svg";
import AreaRangeChartSvg from "assets/images/chart/AreaRange.svg";
import BarChartIcon from "@mui/icons-material/BarChart";
import ChartSidebar from "../details/ChartSidebar";

// import HorizontalBarChart from "assets/images/chart/HorizontalBar.svg";
import ColumnRangeSvg from "assets/images/chart/ColumnRange.svg";
import PointMarkerSvg from "assets/images/chart/PointMarker.svg";
import ScatterLineChart from "assets/images/chart/ScatterLine.svg";
// import DepthChart from "assets/images/chart/DepthChart.svg";
import {addSeries, clearSeries, deleteSeries, setProductOpt} from "store/slices/StockProductSlice";
import BellCurvePng from "assets/images/chart/BellCurve.png";
// import GroupedBarChart from "assets/images/chart/GroupedBar.svg";
// import StackedBarChart from "assets/images/chart/StackedBar.svg";
import PyramidColumnSvg from "assets/images/chart/PyramidColumn.svg";
// import PyramidColumnGroupedSvg from "assets/images/chart/PyramidColumnGrouped.svg";
// import PyramidColumnStackSvg from "assets/images/chart/PyramidColumnStack.svg";
// import HistogramSvg from "assets/images/chart/Histogram.svg";
import OHLCChartSvg from "assets/images/chart/OHLC.svg";
import HLCChartPng from "assets/images/chart/HLC.png";
import CandlestickChart from "assets/images/chart/Candlestick.svg";
import HeikinAshiChart from "assets/images/chart/HeikinAshi.svg";
import HollowCandleChart from "assets/images/chart/HollowCandle.svg";

export const ChartPanel = () => {
  const dispatch = useDispatch()
  const { marketChartType: chartType, fields, yAxisFields } = useSelector(state => state.productData)
  const timeseriesTypes = [
    {
      value: 'line',
      label: 'Line',
      svg: LineChartSvg,
      description: 'A series of datapoints connected with a straight line'
    },
    {
      value: 'area',
      label: 'Area',
      svg: AreaChartSvg,
      description: 'Visual representation of data that utilizes both lines and filled areas to convey information'
    },
    {
      value: 'arearange',
      label: 'Area Range',
      svg: AreaRangeChartSvg,
      description: 'Line charts displaying a range between a lower and higher value for each point'
    },
    // {
    //   value: 'bellcurve',
    //   label: 'Bell Curve',
    //   svg: BellCurvePng,
    //   description: 'Graphical representation of a normal (Gaussian) probability distribution'
    // },
    // { val: 'bar', label: 'Bar', svg: HorizontalBarChart },
    {
      value: 'column',
      label: 'Column',
      icon: () => <BarChartIcon />,
      description: 'Display data as vertical bars'
    },
    {
      value: 'columnrange',
      label: 'Column Range',
      svg: ColumnRangeSvg,
      description: 'Columnrange charts are column charts displaying a range between a lower and higher value for each point'
    },
    // { value: 'grouped-bar', label: 'Grouped Bar', svg: GroupedBarChart},
    // { value: 'stacked-bar', label: 'Stacked Bar', svg: StackedBarChart},
    {
      value: 'point-marker',
      label: 'Scatter Marker',
      svg: PointMarkerSvg,
      description: 'A scatter chart draws a single point for each point of data in a series without connecting them'
    },
    {
      value: 'columnpyramid',
      label: 'Column Pyramid',
      svg: PyramidColumnSvg,
      description: 'Like the column chart, is often used to visualize comparisons of data sets with discrete data'
    },
    // { value: 'columnpyramid-grouped', label: 'Grouped Column Pyramid', svg: PyramidColumnGroupedSvg},
    // { value: 'columnpyramid-stack', label: 'Stacked Column Pyramid', svg: PyramidColumnStackSvg},
    {
      value: 'ohlc',
      label: 'OHLC',
      svg: OHLCChartSvg,
      description: "The Open-High-Low-Close chart is typically used to show the change in price over a period of time. A vertical line shows the range of the price change where the top of the line is the highest and the bottom is the lowest."
    },
    {
      value: 'hlc',
      label: 'HLC',
      svg: HLCChartPng,
      description: 'The High-Low-Close chart is typically used to show the change in price over a period of time. A vertical line shows the range of the price change where the top of the line is the highest and the bottom is the lowest. Single tickmark indicates the close value.'
    },
    // { val: 'horizontal-bar', label: 'Horizontal Bar'},
    // { val: 'horizontal-stacked-bar', label: 'Horizontal Stacked Bar'},
    {
      value: 'candlestick',
      label: 'Candlestick',
      svg: CandlestickChart,
      description: 'A candlestick chart is typically used to present the open, high, low and close price over a period of time. A candlestick chart is composed of a body and an upper and a lower wick. The body represents the opening and closing price. If the opening price is higher than the closing price the body is filled.'
    },
    {
      value: 'heikinashi',
      label: 'Heikin-Ashi',
      svg: HeikinAshiChart,
      description: 'The Heikin-Ashi chart is based on candlesticks; however, it has the following differences to the standard candlestick chart: Each candle is built upon the previous candle as it is processed (averaged depending on the previous candle data.'
    },
    {
      value: 'hollowcandlestick',
      label: 'Hollow Candle',
      svg: HollowCandleChart,
      description: "Hollow candlesticks are practical to display price behavior using color and fill attributes. A hollow candle indicates that the current closing price is higher than the same period's open price"
    },
    {
      value: 'scatter-line',
      label: 'Scatter Marker Line',
      svg: ScatterLineChart,
      description: 'Combination of scatter and line series'
    },
    // { value: 'depth-chart', label: 'Depth Chart', svg: DepthChart},
  ];
  const onChangeChartType = (value) => {
    // dispatch(clearSeries())
    dispatch(deleteSeries('default'))
    if(['candlestick', 'ohlc', 'hlc', 'hollowcandlestick', 'heikinashi'].includes(value)){
      const seriesPayload = {
        type: 'ohlc',
        id: 'default'
      }
      if(fields.findIndex(item => item.id === 'open') > -1){
        seriesPayload.open = 'open'
      }else{
        seriesPayload.open = yAxisFields[0].id
      }
      if(fields.findIndex(item => item.id === 'close') > -1){
        seriesPayload.close = 'close'
      }else{
        seriesPayload.close = yAxisFields[0].id
      }
      if(fields.findIndex(item => item.id === 'low') > -1){
        seriesPayload.low = 'low'
      }else{
        seriesPayload.low = yAxisFields[0].id
      }
      if(fields.findIndex(item => item.id === 'high') > -1){
        seriesPayload.high = 'high'
      }else{
        seriesPayload.high = yAxisFields[0].id
      }
      dispatch(addSeries(seriesPayload))
    } else if(['arearange', 'columnrange'].includes(value)){
      dispatch(addSeries({
        id: 'default',
        type: 'range',
        lower: 'low',
        higher: 'high',
      }))
    }else{
      dispatch(addSeries({
        id: 'default',
        value: 'close',
      }))
    }
    dispatch(setProductOpt({ path: 'marketChartType', value }))
  }

  return (
    <ChartSidebar
      selectedChartType={chartType}
      onSelectChartType={onChangeChartType}
      chartTypes={timeseriesTypes}
    />
  )
};
