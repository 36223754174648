import React from 'react';
import { makeStyles } from "@mui/styles";

import {
  Grid,
} from "@mui/material";
import {useSelector} from "react-redux";
import {PieDataPanel} from "./Pie";
import DefaultPanel from "./Default";

const useStyles = makeStyles(() => ({
  root: {
    padding: '10px'
  }
}));

export const DataPanel = () => {
  const { nonMarketChartType: chartType } = useSelector(state => state.productData)
  const classes = useStyles()

  return (
    <Grid container spacing={1} className={classes.root}>
      {
        chartType === 'pie' ? (
          <PieDataPanel />
        ) : (
          <DefaultPanel />
        )
      }
    </Grid>
  )
};
