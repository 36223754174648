import React, { useEffect, useRef } from "react";
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
// import Highcharts3D from 'highcharts/highcharts-3d'
import IndicatorsAll from 'highcharts/indicators/indicators-all'

import 'highcharts/css/stocktools/gui.scss'
import 'highcharts/css/annotations/popup.scss'

import StockData from 'highcharts/modules/data'
import HighchartAccessibility from 'highcharts/modules/accessibility'
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import DragPane from 'highcharts/modules/drag-panes'
import AnnotationsAdvanced from 'highcharts/modules/annotations-advanced'
import PriceIndicator from 'highcharts/modules/price-indicator'
import FullScreen from 'highcharts/modules/full-screen'
import StockTools from 'highcharts/modules/stock-tools'
import Heikinashi from 'highcharts/modules/heikinashi'
import HollowCandlestick from 'highcharts/modules/hollowcandlestick'
import HistogramBellCurve from 'highcharts/modules/histogram-bellcurve'
import Gantt from 'highcharts/modules/gantt'

import HighchartsMore from 'highcharts/highcharts-more';
import {Box} from "@mui/system";

// Highcharts3D(Highcharts)
StockData(Highcharts)
DragPane(Highcharts)
IndicatorsAll(Highcharts)
StockTools(Highcharts)
AnnotationsAdvanced(Highcharts)
PriceIndicator(Highcharts)
FullScreen(Highcharts)
HighchartAccessibility(Highcharts)

Heikinashi(Highcharts)
HollowCandlestick(Highcharts)
Gantt(Highcharts)
HistogramBellCurve(Highcharts)

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

function ChartContainer(props) {
  const { options } = props
  const chartRef = useRef(null);
  const boxRef = useRef(null);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (chartRef.current) {
        chartRef.current.chart.reflow();
      }
    });

    if (boxRef.current) {
      observer.observe(boxRef.current);
    }

    return () => {
      if (boxRef.current) {
        observer.unobserve(boxRef.current);
      }
    };
  }, []);

  return (
    <Box
      ref={boxRef}
      style={{ width: '100%',marginTop: '30px' }}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...options,
          exporting: {
            enabled: false
          }
        }}
        constructorType={'stockChart'}
        ref={chartRef}
      />
    </Box>
  )
}

export default ChartContainer