import React, {useState, useContext, useEffect, useMemo, useCallback} from "react";
import { ProductContext } from "./ProductContext";
import {useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  fetchProductSQL,
  initProductMetaData,
  setSelectedBlobTimeIncrement,
  setSelectedDataOpt,
  setProductOpt,
  fetchStockProductData,
  fetchStockProductOfBigQuery,
  setSelectedFrequencies,
  setXAxisFields,
  setYAxisFields,
  setXAxis,
  setFields,
  addSeries,
  clearAllSeries
} from "store/slices/StockProductSlice";
import utils from "shared/utilities/product";

const TIMESEARIES_SOURCES = ['BLS', 'BOE', 'BEA', 'BCB', 'BP', 'CFTC', 'HKEX', 'RATEINF', 'ML', 'FISCAL_DATA', 'OECD', 'MULTPL', 'EIA', 'USTREASURY', 'ECONODAY', 'QUANDL', 'URL', 'FRED', 'BATS', 'YC', 'QOR', 'ZILLOW', 'SGE', 'ODA', 'FINRA', 'WB', 'WASDE', 'ECONOMIST', 'BCHAIN']
const NDL_SOURCES = ['BEA', 'ECONODAY', 'FRED', 'BATS', 'BCIW', 'YC', 'BCMX', 'QOR', 'ZILLOW', 'BCEUX', 'SGE', 'ODA', 'FINRA', 'WB', 'WASDE']

export const useProductContext = () => {
  const context = useContext(ProductContext);
  if (context === undefined) {
    throw new Error('useDataContext must be used within a DataProvider');
  }
  return context;
};

function ProductProvider(props) {
    const [data, setData] = useState([]);
    const [feedSourceType, setFeedSourceType] = useState('stock')
    const { feedSource, databaseCode, productCode = '' } = useParams()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const bookmarkedXAxis = searchParams.get('xAxis');
    // const bookmarkedYAxis = searchParams.get('yAxis');
    const bookMarkedTimeIncrement = searchParams.get('timeIncrement');
    const {
      qaMode,
      selectedBlobTimeIncrement: timeIncrement,
      bgTables,
      loadedProductData,
      selectedDataOpt,
      timeIncrements,
      signedUrls
    } = useSelector(state => state.productData)
    const dispatch = useDispatch()

    // Consider to remove
    useEffect(() => {
      if (TIMESEARIES_SOURCES.indexOf(feedSource.toUpperCase()) > -1) {
        setFeedSourceType('timeseries')
      }else{
        setFeedSourceType('stock')
      }
    }, [
      feedSource
    ])
  const beaDatabaseCode = useMemo(() => {
      const transformeds = ['NIPA', 'FixedAssets', 'NIUnderlyingDetail', 'IO', 'GDPbyIndustry', 'underlyingGDPbyIndustry', 'IIP', 'IntlServTrade', 'ITA', 'MNE']
      if (databaseCode === 'ITA' && productCode === 'TOTAL') return ''
      if (feedSource === 'BEA' && transformeds.indexOf(databaseCode) > -1) {
        return databaseCode
      }
      return ''
    }, [
      feedSource, databaseCode
    ])
    const isNDLSource = useMemo(() => {
      return NDL_SOURCES.indexOf(feedSource.toUpperCase()) > -1
    })

    const identifier = useMemo(() => {
      let _identifier = `${selectedDataOpt === 'big-query' ? 'bg/' : ''}${feedSource}/${databaseCode}/${productCode}`
      if (timeIncrement) {
        _identifier += `/${timeIncrement}`
      }
      return _identifier
    }, [feedSource, databaseCode, productCode, timeIncrement, selectedDataOpt])

    const fetchProductData = async (_timeIncrement) => {
      if (!(loadedProductData && loadedProductData[identifier] && loadedProductData[identifier].length > 0)) {
        // if (timeIncrement) {
        // let tPromise =  new Promise(resolve => {
        const _params = {
          feed_source: feedSource,
          database_code: databaseCode,
          product_code: productCode,
          timeIncrement: _timeIncrement,
          bg_table_location: bgTables[_timeIncrement] || ''
        }
        try {
          if (selectedDataOpt === 'big-query') {
            await dispatch(fetchStockProductOfBigQuery(_params))
          } else {
            await dispatch(fetchStockProductData(_params))
          }
        } catch (e) {
          console.log('Error:', e)
        }
        // });
        // await tPromise
        // }
      }
    }

    useEffect(() => {
      const _qa = JSON.parse(new URLSearchParams(location.search).get('qa'));
      dispatch(setProductOpt({
        path: 'qaMode',
        value: _qa ?? false
      }))
    }, [location.search]);
    useEffect(() => {
      if (qaMode !== undefined) {
        if (!qaMode) {
          dispatch(setSelectedDataOpt('cloud-storage'))
          // }
        } else {
          dispatch(setSelectedDataOpt(null))
          dispatch(setSelectedBlobTimeIncrement(null))
        }
      }
    }, [
      qaMode,
      feedSource,
    ])

    useEffect(() => {
      try {
        dispatch(initProductMetaData())
        if (qaMode !== undefined && !qaMode) {
          dispatch(fetchProductSQL({
            feed_source: feedSource,
            database_code: databaseCode,
            product_code: productCode
          }))
        }
        // dispatch(setFeedSourceType(feedSourceType))
        // dispatch(setLoadedFeedSourceType(feedSourceType))
      } catch (e) {
        console.log(e, 'error')
      }
    }, [
      productCode,
      databaseCode,
      feedSource,
      qaMode,
      beaDatabaseCode
    ])
    useEffect(() => {
      setData([])
      dispatch(clearAllSeries())
    }, [identifier]);

    useEffect(() => {
      if (timeIncrement !== null || (qaMode && selectedDataOpt)) {
        if (beaDatabaseCode === '') {
          fetchProductData(timeIncrement)
        } else if (qaMode) {
        }
      }
    }, [
      timeIncrement,
      selectedDataOpt,
      qaMode,
      beaDatabaseCode
    ])

    const initFetchingData = useCallback(async () => {
      if (!qaMode) {
        if (beaDatabaseCode !== '') {
          if (timeIncrements) {
            // fetchSignedUrl([timeIncrements[0]])
            // await fetchAllProductData()
            dispatch(setSelectedFrequencies([timeIncrements[0]]))
            // console.log(productData.signedUrls, 'productData.signedUrls')
            // executeDataTransformLogic()
          }
        } else {
          if (timeIncrements) {
            if (bookMarkedTimeIncrement) {
              dispatch(setSelectedBlobTimeIncrement(bookMarkedTimeIncrement))
            }else if (!timeIncrement && timeIncrements && (timeIncrements[0] !== undefined)) {
              // }else if (!timeIncrement && timeIncrements && (timeIncrements !== [''])) {
              dispatch(setSelectedBlobTimeIncrement(timeIncrements[0]))
            }
          }
        }
      }
    }, [
      timeIncrements,
      signedUrls,
      timeIncrement,
      qaMode,
      beaDatabaseCode,
      selectedDataOpt,
      bookMarkedTimeIncrement
    ])
    useEffect(() => {
      initFetchingData()
    }, [
      timeIncrements,
      timeIncrement,
      qaMode,
      selectedDataOpt
    ])

  const formatStock = (data) => {
    const _data = JSON.parse(JSON.stringify(data))
    _data.forEach((d) => {
      let sDate = null
      if (d.reading_date) {
        sDate = new Date(d.reading_date)
      } else if (d.timestamp) {
        sDate = new Date(d.timestamp)
      } else if (d.date) {
        sDate = new Date(d.date)
      }
      d.date = sDate
      d.close = +d.close
      d.open = +d.open
      d.high = +d.high
      d.low = +d.low
      d.volume = +d.volume
      d.absoluteChange = ""
      d.dividend = ""
      d.percentChange = ""
      d.split = ""
    })
    return _data
  }

  const formatNonMarket = (initials, options = {}) => {
    const res = [];
    const { xType, xConfiguration } = options;
    if (Array.isArray(initials)) {
      initials.map(item => {
        const _record = {}
        if (xType && xType === 'customized') {
          let customizedDate = null
          for (let xConfig of xConfiguration) {
            if (customizedDate !== null) break;
            if (xConfig.defaultDate) {
              if (new Date(item[xConfig.defaultDate]).toDateString() !== 'Invalid Date') {
                customizedDate = utils.parsePotentialData(item[xConfig.defaultDate])
              }
            } else if (xConfig.start_date) {
              const year = new Date(item[xConfig.start_date])
              if (year.toDateString() !== 'Invalid Date') {
                if (isNaN(item[xConfig.day])) {
                  if (new Date(item[xConfig.day]).toDateString() !== 'Invalid Date') {
                    customizedDate = utils.parsePotentialData(item[xConfig.day])
                  }
                } else {
                  customizedDate = [year.getFullYear(), item[xConfig.month], item[xConfig.day]].join('-');
                }
              }
            }
          }
          if (customizedDate && new Date(customizedDate).toDateString() !== 'Invalid Date') {
            _record.customized = customizedDate;
          } else {
            _record.customized = undefined;
          }
        }
        for (let key in item) {
          _record[key.toLocaleLowerCase()] = item[key]
        }
        res.push(_record)
      })
    }
    return res;
  }

  const formatToChartable = (value) => {
    let _value = value
    if (_value == null) {
      return _value
    }
    if (typeof _value === 'object' && _value.hasOwnProperty('value')) {
      _value = _value.value
    }
    if (_value == null) {
      return _value
    }
    if (!isNaN(_value)) {
      return +_value
    }
    if (!isNaN(Date.parse(_value))) {
      const fDate = new Date(_value)
      return [fDate.getFullYear(), fDate.getMonth() + 1, fDate.getDate()].join('-')
    }
    return _value
  }
  const deDuplicate = (res) => {
    let list = []
    let deDuplicated = []
    let _data
    if (res.data) {
      _data = res.data
    } else _data = [...res]
    _data.forEach(element => {
      if (!list.includes(JSON.stringify(element))) {
        deDuplicated.push(element)
        list.push(JSON.stringify(element))
      }
    })
    return deDuplicated
  }

  const formatData = async () => {
    let verifiedX = null, verifiedY = null;
    if (loadedProductData[identifier] && loadedProductData[identifier].length > 0) {
      let initialData = deDuplicate(JSON.parse(JSON.stringify(loadedProductData[identifier])))
      // dispatch(initiTimeseriesData())
      // dispatch(setFields([]))
      let isEvaluated = false
      initialData.map((d) => {
        /** Customize the data into chart format, and get the verified X & Y based on default settings*/
        for (let field in d) {
          d[field] = formatToChartable(d[field])
          if (!isEvaluated) {
            isEvaluated = true
          }
        }
        return utils.replaceSpace(d)
      })
      //Update settings
      if (initialData.length > 0) {
        let innerFeedSourceType
        const { fields: _fields } = utils.attributeTransformer(initialData)
        console.log(_fields, '_fields')
        dispatch(setFields([..._fields]))
        const requiredIds = ['open', 'low', 'close', 'high'];
        const hasValidId = requiredIds.every(requiredId => _fields.some(item => item.id === requiredId));
        if (!hasValidId) {
          innerFeedSourceType = 'timeseries'
          setFeedSourceType('timeseries')
        }else{
          innerFeedSourceType = 'stock'
          setFeedSourceType('stock')
        }
        let xAxisFields = _fields.filter(f => ['date', 'datenumber', 'string'].indexOf(f.type) > -1)
        let yAxisFields = _fields.filter(f => ['number', 'datenumber'].indexOf(f.type) > -1)
        let innerXAxis =null;
        dispatch(setXAxisFields([...xAxisFields]))
        dispatch(setYAxisFields([...yAxisFields]))
        console.log(innerFeedSourceType, 'feedSourceType')
        if (innerFeedSourceType !== 'stock') {
          if(bookmarkedXAxis){
            innerXAxis = bookmarkedXAxis
          }else if (xAxisFields[0] && xAxisFields[0].id) {
            innerXAxis = xAxisFields[0].id
          }
          dispatch(addSeries({
            id: 'default',
            value: yAxisFields[0].id
          }))
          // if(bookmarkedYAxis){
          //   dispatch(setYAxis(bookmarkedYAxis.split(',')))
          // } else if (yAxisFields[0] && yAxisFields[0].id) {
          //   dispatch(setYAxis([yAxisFields[0].id]))
          // }
        }else{
          dispatch(addSeries({
            value: 'close',
            id: 'default'
          }))
          const xAxisIdx = xAxisFields.findIndex(item => item.id === 'date' || item.id === 'timestamp')
          if(xAxisIdx > -1){
            innerXAxis = xAxisFields[xAxisIdx].id
          }else{
            innerXAxis = xAxisFields[0].id
          }
          dispatch(setProductOpt({ path: 'chartType', value: 'ohlc' }))
        }
        if(innerXAxis){
          dispatch(setXAxis(innerXAxis))
          const xAxisType = _fields.filter(item => item.id === innerXAxis)[0]
          if(['date', 'datenumber'].includes(xAxisType)){
            initialData.sort((a, b) => {
              if(a[innerXAxis] && b[innerXAxis]){
                return new Date(a[innerXAxis]).getTime() - new Date(b[innerXAxis]).getTime()
              }
              return 1;
            })
          }
        }else{
          dispatch(setXAxis(_fields[0]))
        }
        console.log(initialData, 'initialData')
        setData([...initialData])
      }
    }

  }

  useEffect(() => {
    formatData()
  }, [
    loadedProductData,
    identifier,
    selectedDataOpt,
  ])

  const clickBlobHandler = (blob) => {
    if (timeIncrements && !timeIncrement) {
      if (timeIncrements && (timeIncrements[0] !== undefined) && beaDatabaseCode === '' && timeIncrement === null) {
        // if (timeIncrements && (timeIncrements !== ['']) && beaDatabaseCode === '' && timeIncrement === null) {
        dispatch(setSelectedBlobTimeIncrement(timeIncrements[0]))
      }
    }
    dispatch(setSelectedDataOpt(blob))
  }

    return (
      <ProductContext.Provider value={{
        data,
        setData,
        identifier,
        feedSource,
        databaseCode,
        productCode,
        beaDatabaseCode,
        feedSourceType,
        isNDLSource,
        clickBlobHandler
      }}>
        {props.children}
      </ProductContext.Provider>
    );
}

export default ProductProvider