import React, { useRef, useMemo } from "react";
import { useSelector } from 'react-redux'
import moment from 'moment'
import Highcharts from 'highcharts/highstock'
import IndicatorsAll from 'highcharts/indicators/indicators-all'

import 'highcharts/css/stocktools/gui.scss'
import 'highcharts/css/annotations/popup.scss'

import StockData from 'highcharts/modules/data'
import HighchartAccessibility from 'highcharts/modules/accessibility'
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import DragPane from 'highcharts/modules/drag-panes'
import AnnotationsAdvanced from 'highcharts/modules/annotations-advanced'
import PriceIndicator from 'highcharts/modules/price-indicator'
import FullScreen from 'highcharts/modules/full-screen'
import StockTools from 'highcharts/modules/stock-tools'
import Heikinashi from 'highcharts/modules/heikinashi'
import HollowCandlestick from 'highcharts/modules/hollowcandlestick'
import Gantt from 'highcharts/modules/gantt'

import HighchartsMore from 'highcharts/highcharts-more';
import StockHighChart from "views/product/details/chart/StockHighChart";

// Highcharts3D(Highcharts)
StockData(Highcharts)
DragPane(Highcharts)
IndicatorsAll(Highcharts)
StockTools(Highcharts)
AnnotationsAdvanced(Highcharts)
PriceIndicator(Highcharts)
FullScreen(Highcharts)
HighchartAccessibility(Highcharts)

Heikinashi(Highcharts)
HollowCandlestick(Highcharts)
Gantt(Highcharts)

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

// import DepthChart from './DepthChart'

function getRandomColor() {
  // Generate random values for red, green, and blue channels
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  // Combine the channels into a CSS color string
  return `rgb(${red}, ${green}, ${blue})`;
}

const useData = (initialData, xAxis = null, yAxisFields = [], chartType) => {


  let defaultSeries = [], volume = [], duelSeries = {}
  const _dateField = xAxis || 'date'

  // const pointList = ['open', 'low', 'high', 'close']
  if (!(xAxis && yAxisFields && yAxisFields.length > 0)) return [{}]
  // if (yAxisFields?.length > 0) {
  //   console.log(yAxisFields, 'yAxisfields')
  //   yAxisFields.map((chartLine, idx) => {
  //     duelSeries[`Series_${idx}`] = {
  //       name: `Series ${idx}`,
  //       color: getRandomColor(),
  //       data: initialData.map(item => {
  //         const t = new Date(item[_dateField])
  //         const pointDate = t.getTime();
  //         let seriesPoint = [
  //           pointDate
  //         ]
  //         for(let pointField of pointList){
  //           seriesPoint.push(item[chartLine[pointField]])
  //         }
  //         return seriesPoint
  //       })
  //     }
  //   })
  // }

  // for (let idx in yAxisFields){
  //   const yField = yAxisFields[idx]
  //   if(idx === 0){
  //     duelSeries['ohlc'] =  initialData.map(item => {
  //       const t = new Date(d[_dateField])
  //       const pointDate = t.getTime();
  //       let seriesPoint = [
  //         pointDate
  //       ]
  //     })
  //   }
  // }

  for (let d of initialData) {
    const t = new Date(d[_dateField])
    const pointDate = t.getTime();
    let seriesPoint = []
    yAxisFields.map((field, idx) => {
      let duelKey = field.value
      // if(['ohlc', 'range'].includes(field.type)){
      //   duelKey = 'default'
      // }
      if(field.id === 'default'){
        duelKey = 'default'
      }

      if (duelSeries[duelKey] === undefined) {
        duelSeries[duelKey] = {
          name: duelKey.toUpperCase(),
          color: getRandomColor(),
          data: [],
          type: duelKey === 'default' ? chartType : 'line',
          marker: {
            enabled: false,
            radius: 0
          },
          lineWidth: 2,
        }
        if(chartType === 'point-marker'){
          duelSeries[duelKey].type = 'scatter'
          duelSeries[duelKey].marker = {
            enabled: true,
            radius: 2
          }
          duelSeries[duelKey].lineWidth = 0
        }
        // if(field.type === 'ohlc'){
        //   duelSeries[duelKey].data = {
        //     open: [],
        //     high: [],
        //     low: [],
        //     close: []
        //   }
        // }
      }
      if(field.type === 'ohlc'){
        seriesPoint = [pointDate, d[field.open], d[field.high], d[field.low], d[field.close]]
        duelSeries[duelKey].data.push([pointDate, d[field.open], d[field.high], d[field.low], d[field.close]])
        // duelSeries[duelKey].data.open.push([pointDate, d[field.open]])
        // duelSeries[duelKey].data.high.push([pointDate, d[field.high]])
        // duelSeries[duelKey].data.low.push([pointDate, d[field.low]])
        // duelSeries[duelKey].data.close.push([pointDate, d[field.close]])
      }else if(field.type === 'range'){
        duelSeries[duelKey].data.push([pointDate, d[field.lower] || 0, d[field.higher] || 0])
      }else{
        duelSeries[duelKey].data.push([pointDate, d[field.value]])
      }
    })
    // defaultSeries.push(seriesPoint)
    if(d.volume){
      volume.push([
        pointDate, d.volume
      ])
    }
  }
  let data = duelSeries
  console.log(duelSeries, 'duelSeries')
  return {
    data, volume
  }
}
const useSeries = ({ data: seriesData, volume, config, chartType: initialChartType }) => {
  if (!seriesData) return []
  const {
    step, spline, dashStyle, zones: initialZones
  } = config
  let { scatterEnabled, scatterRadius, shadow } = config
  console.log(initialChartType, 'initialChartType')
  let chartType = initialChartType
  let useOhlcData = false, groupingUnits = [
    [
      'week', // unit name
      [1] // allowed multiples
    ], [
      'month',
      [1, 2, 3, 4, 6]
    ]
  ], lineWidth = 2
  if (initialChartType === 'scatter-line') {
    chartType = 'scatter'
    scatterEnabled = true
    scatterRadius = 2
  }
  if (initialChartType === 'line' && spline) {
    chartType = 'spline'
  } else if (initialChartType === 'area' && spline) {
    chartType = 'areaspline'
  } else if (initialChartType === 'arearange' && spline) {
    chartType = 'areasplinerange'
  } else if (initialChartType === 'bellcurve') {
    chartType = 'scatter'
  } else if (initialChartType === 'point-marker') {
    chartType = 'line'
    lineWidth = 0
    scatterEnabled = true
    scatterRadius = 2
    shadow = false
  } else if (initialChartType === 'ohlc') {
    useOhlcData = true
  } else if (initialChartType === 'grouped-bar' || initialChartType === 'stacked-bar') {
    chartType = 'column'
  } else if (initialChartType === 'columnpyramid-stack' || initialChartType === 'columnpyramid-grouped') {
    chartType = 'columnpyramid'
  } else if (initialChartType === 'percent-area') {
    chartType = 'area'
  }
  console.log(chartType, 'chartType in the series')
  const defaultData = seriesData.default ? seriesData.default : seriesData;
  let seriesList = []
  for(const key in seriesData){
    if(['default'].indexOf(key) < 0){
      seriesList.push(seriesData[key])
    }
  }
  console.log(seriesList, 'seriesList')
  let stockSeries = {
      name: 'Stock',
      type: chartType,
      id: 'stockseries',
      // type: 'gantt',
      marker: {
        enabled: scatterEnabled,
        radius: scatterRadius
      },
      lineWidth: lineWidth,
      shadow: shadow,
      step: step,
      data: seriesData?.default?.data || seriesList[0].data,
      tooltip: {
        valueDecimals: 2
      },
      dataGrouping: {
        // units: groupingUnits
      },
      states: {
        hover: {
          lineWidthPlus: 0
        }
      },
      dashStyle: dashStyle,
      zones: [...initialZones],
      useOhlcData: useOhlcData
    },
    volumeSeries = {
      type: 'column',
      name: 'Volume',
      data: volume,
      yAxis: 1,
      dataGrouping: {
        // units: groupingUnits
      }
    }
  if (initialChartType === 'bellcurve') {
    return [
      {
        name: 'Bell Curve',
        type: 'bellcurve',
        xAxis: 0,
        yAxis: 0,
        baseSeries: 1,
        zIndex: -1,
        intervals: 4,
        pointsInInterval: 5
      },
      {
        ...stockSeries,
        accessibility: {
          exposeAsGroupOnly: true
        },
        marker: {
          radius: 1.5
        }
      },
      volumeSeries
    ]
  } else if (['grouped-bar', 'stacked-bar', 'columnpyramid-stack', 'columnpyramid-grouped', 'percent-area'].indexOf(initialChartType) > -1) {
    const initialSeries = { ...stockSeries, id: undefined, data: [] }
    const oSeries = { ...initialSeries, id: 'stockseries', name: 'Open', color: Highcharts.getOptions().colors[0] },
      hSeries = { ...initialSeries, name: 'High', color: Highcharts.getOptions().colors[1] },
      lSeries = { ...initialSeries, name: 'Low', color: Highcharts.getOptions().colors[2] },
      cSeries = { ...initialSeries, name: 'Close', color: Highcharts.getOptions().colors[3] }
    for (let d of seriesData.ohlc) {
      oSeries.data = seriesData.ohlc.data.open || []
      hSeries.data = seriesData.ohlc.data.high || []
      lSeries.data = seriesData.ohlc.data.low || []
      cSeries.data = seriesData.ohlc.data.close || []
    }
    return [oSeries, hSeries, lSeries, cSeries, volumeSeries]
  }
  // else if(['arearange', 'candlestick', 'ohlc', 'hlc', 'hollowcandlestick'].includes(initialChartType)){
  //   return [stockSeries, volumeSeries, ...seriesList]
  // }
  return [stockSeries, volumeSeries, ...seriesList]
}
const useYAxis = ({ chartType, yPlotBands, yPlotLines }) => {
  const yAxis = [{
    labels: {
      align: 'right',
      x: -3
    },
    title: {
      text: 'Stock'
    },
    height: '60%',
    lineWidth: 2,
    resize: {
      enabled: true
    },
    crosshair: true,
    plotBands: [...yPlotBands],
    plotLines: [...yPlotLines],
    endOnTick: false
  }]
  // if(chartType === 'bellcurve'){
  //     yAxis.push({
  //         title: { text: 'Bell curve' },
  //         opposite: true
  //     })
  // }else{
  // }
  yAxis.push({
    labels: {
      align: 'right',
      x: -3
    },
    title: {
      text: 'Volume'
    },
    top: '65%',
    height: '35%',
    offset: 0,
    lineWidth: 2
  })
  return yAxis
}
const useXAxis = ({ chartType, xPlotBands, xPlotLines }) => {
  const xAxis = [{
    // type: 'datetime',
    crosshair: true,
    // plotBands: [...xPlotBands],
    // plotLines: [...xPlotLines]
  }]
  // if(chartType === 'bellcurve'){
  //     xAxis.push({
  //         title: {
  //             text: 'Bell Curve'
  //         },
  //         alignTicks: false,
  //         opposite: true
  //     })
  // }
  return xAxis
}
const usePlotOptions = ({ chartType, config }) => {
  let {
    pointDataLabelEnabled
  } = config
  let stacking, columnDataLabelEnabled = false
  if (['stacked-bar', 'columnpyramid-stack'].indexOf(chartType) > -1) {
    stacking = 'normal'
    columnDataLabelEnabled = true
  } else if (['percent-area'].indexOf(chartType) > -1) {
    stacking = 'percent'
  }
  return {
    series: {
      showInNavigator: true,
      gapSize: 6,
      allowPointSelect: true,
      // compare: 'value',
      // cumulative: true,
      enabled: pointDataLabelEnabled,
      dataLabels: {
        enabled: columnDataLabelEnabled
      }
    },
    flags: {
      color: Highcharts.getOptions().colors[0], // same as onSeries
      fillColor: Highcharts.getOptions().colors[0],
      // width: 16,
      style: { // text style
        color: 'white'
      },
      states: {
        hover: {
          fillColor: '#395C84' // darker
        }
      },
      textAlign: 'center'
    },
    line: {
    },
    column: {
      stacking: stacking,
      dataLabels: {
        enabled: columnDataLabelEnabled
      }
      // depth: 25
    },
    columnpyramid: {
      stacking: stacking,
      dataLabels: {
        enabled: columnDataLabelEnabled
      }
    },
    area: {
      stacking: stacking,
      lineColor: '#666666',
      lineWidth: 1,
      marker: {
        lineWidth: 1,
        lineColor: '#666666'
      }
    },
    scatter: {
      marker: {
        radius: 5,
        states: {
          hover: {
            enabled: true,
            lineColor: 'rgb(100,100,100)'
          }
        }
      },
      states: {
        hover: {
          marker: {
            enabled: false
          }
        }
      },
      tooltip: {
        headerFormat: '<b>{series.name}</b><br>',
        pointFormat: '{point.x} cm, {point.y} kg'
      }
    }
  }
}

function ChartContainer(props) {
  const chartComponent = useRef(null);
  const { data: initialData, chartType, yAxisFields } = props
  console.log(chartType, 'chartType')
  const highChartData = useSelector(state => state.highChartData) || {}
  const chartSettings = useSelector(state => state.productData.chartSettings)
  const {
    // plotBands, plotLines, theme
  } = highChartData
  const plotBands = []
  const plotLines = []
  const xPlotBands = plotBands.filter(item => item.axis = 'x')
  const yPlotBands = plotBands.filter(item => item.axis = 'y')
  const xPlotLines = plotLines.filter(item => item.axis = 'x')
  const yPlotLines = plotLines.filter(item => item.axis = 'y')

  const { data, volume } = useData(initialData, props.xAxis, yAxisFields, chartType)
  const series = useSeries({
    config: highChartData, data, volume, chartType
  })
  console.log(series, 'series')
  const yAxis = useYAxis({ chartType, yPlotBands, yPlotLines })
  const xAxis = useXAxis({ chartType, xPlotBands, xPlotLines })
  const plotOptions = usePlotOptions({ chartType, config: highChartData })

  // const options = { style: 'currency', currency: 'USD' };
  // const numberFormat = new Intl.NumberFormat('en-US', options);
  const configStock = useMemo(() => ({
    ...chartSettings,
    chart: {
      ...chartSettings.chart,
      height: 600,
      alignTicks: true,
      zoomType: 'x',
      // options3d: {
      //     enabled: true,
      //     alpha: 15,
      //     beta: 15,
      //     depth: 50,
      //     viewDistance: 25
      // }
      events: {
        // addSeries: function(event) {
        //     // Function which saves the new background color.
        //     console.log(event, 'event')
        //     console.log(this.chart, 'this.chart')
        //     if(event.options.type === 'flags'){
        //         event.preventDefault()
        //         // console.log(this.chart, 'this.chart.series')
        //         event.target.options.series.push(event.options.data[0])
        //         // event.target.series.redraw()
        //     }
        // }
      }
    },
    exporting: {
      buttons: {
        enabled: false
      }
    },
    stockTools: {
      gui: {
        enabled: false,
        buttons: [
          // 'typeChange',
          // 'separator',

          // 'thresholds',
          // 'separator',
          // 'indicators',
          // 'separator',
          // 'simpleShapes',
          // 'lines',
          // 'crookedLines',
          // 'measure',
          // 'advanced',
          // 'toggleAnnotations',
          // 'separator',
          // 'verticalLabels',
          // 'flags',
          // 'separator',
          // 'zoomChange',
          // 'fullScreen',
          // 'separator',
          // 'currentPriceIndicator',
          // 'saveChart'
        ],
        definitions: {
          thresholds: {
            className: 'highcharts-threshold-annotation',
            symbol: 'horizontal-line.svg'
          },
          typeChange: {
            items: ['typeOHLC', 'typeLine']
          },
          flags: {
            items: ['flagCirclepin', 'flagDiamondpin', 'flagSquarepin', 'flagSimplepin']
          }
        }
      }
    },
    navigation: {
      bindings: {
        thresholds: {
          className: 'highcharts-threshold-annotation',
          start: function (event) {
            var chart = this.chart,
              x = chart.xAxis[0].toValue(event.chartX),
              y = chart.yAxis[0].toValue(event.chartY),
              colors = chart.options.colors,
              series = chart.series[0],
              zones = series.userOptions.zones || [];

            chart.customColorIndex = chart.customColorIndex || 1;

            chart.customColorIndex++;

            if (
              chart.customColorIndex === colors.length
            ) {
              chart.customColorIndex = 1;
            }

            zones.push({
              color: colors[chart.customColorIndex],
              value: y
            });

            chart.addAnnotation({
              langKey: 'thresholds',
              zoneIndex: zones.length - 1,
              type: 'infinityLine',
              draggable: 'y',
              events: {
                drag: function (e) {
                  var newZones = series.userOptions.zones;

                  newZones[this.userOptions.zoneIndex].value =
                    chart.yAxis[0].toValue(e.chartY);

                  chart.series[0].update({
                    zones: newZones
                  });
                }
              },
              typeOptions: {
                type: 'horizontalLine',
                points: [{
                  x: x,
                  y: y
                }]
              }
            });

            chart.series[0].update({
              zones: zones
            });
          }
        }
      }
    },
    yAxis: yAxis,
    navigator: {
      series: {
        color: 'red'
      }
    },
    tooltip: {
      shared: true,
      formatter: function () {
        return this.y + '</b><br/>' + moment(this.x).format('MMMM Do YYYY, h:mm')
      },
      split: true,
    },
    plotOptions: plotOptions,

    credits: {
      enabled: false
    },

    legend: {
      enabled: true
    },
    xAxis: xAxis,
    rangeSelector: {
      enabled: false
    },
    series: series,
    responsive: {
      rules: [{
        condition: {
          minWidth: 600
        },
        chartOptions: {
          yAxis: [{
            labels: {
              align: 'left'
            }
          }, {
            labels: {
              align: 'left'
            }
          }]
        }
      }]
    }
  }), [
    series,
    plotOptions,
    xAxis,
    yAxis,
    chartSettings
  ])


  if (chartType === 'depth-chart') {
    return <div>DepthChart</div>
  }

  return (
    <StockHighChart
      options={configStock}
    />
  )
}

export default ChartContainer