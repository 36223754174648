import React from "react";

import {makeStyles} from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',
    marginRight: '3px',
  },
  Label: {
    marginBottom: '3px',
    fontSize: '.75rem'
  },
  Select: {
    fontFamily: 'Poppins',
      // textTransform: 'uppercase',
      // cursor: 'pointer',
      background: 'transparent',
      width: '100%',
      color: '#849095',
      borderRadius: '4px !important',
      backgroundColor: 'rgb(255, 255, 255)',
      boxShadow: '0 0 1px 1px #e5e5f1',
      border: '1px solid transparent !important',
      padding: '9px 11px',
      fontSize: '13px',
      lineHeight: 1,
      '&:focus': {
        outline: 'none',
        boxShadow: '0 0 4px -1px #a3a7ad'
      },
      '&.custom-form-small': {
        padding: '3px',
        fontSize: '13px'
      }
  },
}));

export default function CustomFormSelect(_props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { label, value, options, fieldName, className } = _props
  // Default values initialization
  let disabled = false;
  let size = _props.size || ''

  let labelText = _props.label;

  const renderOpt = (_option, i) => {
    if (typeof _option === 'object') {
      return <option value={_option.value} key={i}>{_option.label}</option>
    } else {
      return <option value={_option} key={i}>{_option}</option>
    }
  }
  const handleChange = (e) => {
    _props.onChange(fieldName ? fieldName : 'field', e.target.value)
  }
  return (
    <>
      <div className={`${classes.root} ${className}`}>
        {
          label && label !== '' &&
          <label className={classes.Label}>{label}</label>
        }
        <select className={classes.Select + ` custom-form-${size}`} defaultValue={value} onChange={handleChange}>
          {
            options.map((o, i) => renderOpt(o, i))
          }
        </select>
      </div>
    </>
  );
}