import React from 'react';
import reactCSS from 'reactcss';
import {
    FormControl,
    FormGroup,
    OutlinedInput,
    InputLabel,
    Select
} from "@mui/material";
import { SketchPicker,TwitterPicker  } from 'react-color';

class ColorPicker extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            displayColorPicker: false
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false });
    };

    render() {
        const { pickerLable, color } = this.props;
        const styles = reactCSS({
            'default': {
                color: {
                    width: '14px',
                    height: '14px',
                    borderRadius: '2px',
                    background: `${ color }`,
                },
                swatch: {
                    borderRadius: '1px',
                    boxShadow: 'rgb(0 0 0 / 27%) 2px 3px 4px 1px',
                    display: 'inline-block',
                    cursor: 'pointer'
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                    transform: 'translate(-10px, 9px)'
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                    width: '100%',
                    height: '100%'
                },
            },
        });
        return (
            <>
                <div className='colorPickerWrap' style={{padding: '0'}}>
                    <div className='colorPickerLabel' style={{marginBottom: '6px'}}>{pickerLable}</div>
                    <div className='colorPickerField'>
                        <div style={ styles.swatch } onClick={ this.handleClick }>
                            <div style={ styles.color } />
                        </div>
                        { this.state.displayColorPicker ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={ this.handleClose }/>
                            <TwitterPicker
                                color={ color }
                                colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"]}
                                onChange={(e) => {
                                    this.props.handleChange(e)
                                    this.handleClose()
                                }}
                            />
                        </div> : null }
                    </div>
                </div>
            </>
        );
    }
}
export default ColorPicker;