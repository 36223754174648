import React from 'react';

import {
  Grid,
  Box,
} from "@mui/material";
import {DropdownSelect} from "components/common/Product/DropdownSelect";
import {useDispatch, useSelector} from "react-redux";
import {setProductOpt, setXAxis, updateSeries} from "store/slices/StockProductSlice";

export const PieDataPanel = () => {
  const dispatch = useDispatch()
  const { yAxisFields, fields, series, xAxis, categoryField } = useSelector(state => state.productData)

  console.log(series, 'series')

  return (
      <Grid item lg={12} md={12} xs={12}>
        <Box>
          <DropdownSelect
            selectedValue={categoryField}
            onChange={(value) => dispatch(setProductOpt({ path: 'categoryField', value }))}
            options={fields}
            placeholder={'Categories'}
            style={{ marginBottom: '10px' }}
          />
          <DropdownSelect
            selectedValue={xAxis}
            onChange={(val) => dispatch(setXAxis(val))}
            options={fields}
            placeholder={'Date'}
            style={{ marginBottom: '10px' }}
          />
          {
            series.map((item, idx) => (
              <Box key={idx}>
                <DropdownSelect
                  selectedValue={item.value}
                  onChange={(val) => dispatch(updateSeries({ id: item.id, field: 'value', newValue: val }))}
                  options={yAxisFields}
                  placeholder={'Value'}
                  style={{ marginBottom: '10px' }}
                />
                <DropdownSelect
                  selectedValue={item.label}
                  onChange={(val) => dispatch(updateSeries({ id: item.id, field: 'label', newValue: val }))}
                  options={fields}
                  placeholder={'Label'}
                  style={{ marginBottom: '10px' }}
                />
              </Box>
            ))
          }
        </Box>
    </Grid>
  )
};
