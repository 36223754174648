import React, { useState, useMemo } from 'react';
import { makeStyles } from "@mui/styles";

import {
  Grid,
  Box,
  Button, IconButton,
} from "@mui/material";
import {DropdownSelect} from "components/common/Product/DropdownSelect";
import BaseAccordion from '../details/BaseAccordion'
import {useDispatch, useSelector} from "react-redux";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {addSeries, setProductOpt, setXAxis, updateSeries} from "store/slices/StockProductSlice";
import SeriesAccordion from "../details/series/SeriesAccordion";
import RangeSeriesAccordion from "../details/series/RangeSeriesAccordion";
import ExpandablePanelBox from "components/common/Base/ExpandablePanelBox";
import CustomFormInput from "components/common/Base/CustomFormInput";
import ColorPicker from "components/common/ColorPicker";
import DeleteIcon from '@mui/icons-material/Delete';
import {setChartFeature, setChartOpt} from "store/slices/RragChartDataSlice";
import CustomFormSelect from "components/common/Base/CustomFormSelect";

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '.75rem'
  },
  AccordionItem: {
    marginBottom: 10
  },
  FieldItem: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f7f8f8',
    borderRadius: '4px',
    padding: '10px',
    marginTop: 9,
  },
  ColorPicker: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    "& svg": {
      fontSize: '18px'
    }
  },
  ParagraphLabel: {
    fontWeight: 'bold'
  }
}));

export const BasicCustomizePanel = () => {
  const dispatch = useDispatch()
  const { yAxisFields, fields, series, xAxis, chartSettings } = useSelector(state => state.productData)
  const classes = useStyles();
  const [expandedPanel, setExpandedPanel] = useState('series1')
  const [txt, setTxt] = useState('')
  const [color, setColor] = useState('red')

  const onPanelChange = (val) => {
    if(expandedPanel === val){
      setExpandedPanel('')
    }else{
      setExpandedPanel(val)
    }
  }
  const usedFields = useMemo(() => {
    return series.map(item => item.value)
  })
  const options = [
    { label: 'X(2020)', value: 'circle' },
    { label: 'Triangle', value: 'triangle' },
    { label: 'Square', value: 'square' },
  ]
  console.log(series, 'series')

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item lg={12} md={12} xs={12}>
        <ExpandablePanelBox panel={{label: 'Text'}} collapsed={true}>
          <CustomFormInput
            label="Title"
            fieldName="text"
            value={chartSettings?.title?.text}
            onChange={(field, value) => {
              dispatch(setProductOpt({
                path: 'chartSettings.title.text',
                value
              }))
            }}
          />
          <CustomFormInput
            label="Sub Title"
            fieldName="text"
            value={chartSettings?.subtitle?.text}
            onChange={(field, value) => {
              dispatch(setProductOpt({
                path: 'chartSettings.subtitle.text',
                value
              }))
            }}
          />
          <CustomFormInput
            label="Caption"
            type="textarea"
            fieldName="text"
            value={chartSettings?.caption?.text}
            onChange={(field, value) => {
              dispatch(setProductOpt({
                path: 'chartSettings.caption.text',
                value
              }))
            }}
          />
        </ExpandablePanelBox>
        <ExpandablePanelBox panel={{label: 'Appearance'}} collapsed={true}>
          <Box>
            <span className={classes.ParagraphLabel}>Chart Colors</span>
            <Box sx={{ display: 'flex', flexFlow: 'wrap' }}>
              {
                chartSettings?.colors?.map((item, idx) => (
                  <Box className={classes.ColorPicker}>
                    <ColorPicker
                      color={color}
                      pickerLable={''}
                      handleChange={(color) => {
                        setColor(color)
                      }}
                    />
                    <IconButton>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))
              }
            </Box>
          </Box>
          <Box className={classes.FieldItem}>
            <Box sx={{ flex: 1 }}>Background Color</Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <span>{chartSettings?.chart?.backgroundColor || '#fff'}</span>
              <ColorPicker
                color={chartSettings?.chart?.backgroundColor}
                pickerLable={''}
                handleChange={(color) => {
                  dispatch(setProductOpt({
                    path: 'chartSettings.chart.backgroundColor',
                    value: color.hex
                  }))
                }}
              />
            </Box>
          </Box>
        </ExpandablePanelBox>
        <ExpandablePanelBox panel={{label: 'Axes'}} collapsed={true}>
          <BaseAccordion
            seriesLabel="Horizontal Axis"
            isExpanded={expandedPanel === 'horizontal-axis'}
            className={classes.AccordionItem}
            onChange={() => onPanelChange('horizontal-axis')}
          >
            <h1>Horizontal Axis</h1>
          </BaseAccordion>
          <BaseAccordion
            seriesLabel="Vertical Axis"
            isExpanded={expandedPanel === 'vertical-axis'}
            className={classes.AccordionItem}
            onChange={() => onPanelChange('vertical-axis')}
          >
            <h1>Vertical Axis</h1>
          </BaseAccordion>
          <BaseAccordion
            seriesLabel="Second Axis"
            isExpanded={expandedPanel === 'second-axis'}
            className={classes.AccordionItem}
            onChange={() => onPanelChange('second-axis')}
          >
            <h1>Second Axis</h1>
          </BaseAccordion>
        </ExpandablePanelBox>
        <ExpandablePanelBox panel={{label: 'ToolTip'}} collapsed={true}>
          <CustomFormInput
            label=""
            fieldName="text"
            placeholder={`{point.key}`}
            value={txt} onChange={() => {}}
          />
          <CustomFormInput
            label=""
            type="textarea"
            fieldName="text"
            placeholder={`{series.name}: {point.y}`}
            value={txt} onChange={() => {}}
          />
          <CustomFormSelect
            size="small"
            options={options}
            value={txt}
            label={'Add information from data'}
            fieldName="info"
            onChange={(field, value) => {
              // dispatch(setChartFeature({ path: 'scatterLineMarker', value: value }))
            }}
          />
          <Box className={classes.FieldItem}>
            <Box sx={{ flex: 1 }}>Background Color</Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <span>{color}</span>
              <ColorPicker
                color={color}
                pickerLable={''}
                handleChange={(color) => {
                  setColor(color)
                }}
              />
            </Box>
          </Box>
          <Box className={classes.FieldItem}>
            <Box sx={{ flex: 1 }}>Border Color</Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <span>{color}</span>
              <ColorPicker
                color={color}
                pickerLable={''}
                handleChange={(color) => {
                  setColor(color)
                }}
              />
            </Box>
          </Box>
        </ExpandablePanelBox>
        <ExpandablePanelBox panel={{label: 'Legend'}} collapsed={true}>
          <h1>Hi</h1>
        </ExpandablePanelBox>
        <ExpandablePanelBox panel={{label: 'Data labels'}} collapsed={true}>
          <h1>Hi</h1>
        </ExpandablePanelBox>
        {/*<BaseAccordion*/}
        {/*  seriesLabel="Text"*/}
        {/*  isExpanded={expandedPanel === 'text'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('text')}*/}
        {/*>*/}
        {/*  <h1>Text</h1>*/}
        {/*</BaseAccordion>*/}
        {/*<BaseAccordion*/}
        {/*  seriesLabel="Appearance"*/}
        {/*  isExpanded={expandedPanel === 'appearance'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('appearance')}*/}
        {/*>*/}
        {/*  <h1>Appearance</h1>*/}
        {/*</BaseAccordion>*/}
        {/*<BaseAccordion*/}
        {/*  seriesLabel="Axes"*/}
        {/*  isExpanded={expandedPanel === 'axes'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('axes')}*/}
        {/*>*/}
        {/*  <h1>axes</h1>*/}
        {/*</BaseAccordion>*/}
        {/*<BaseAccordion*/}
        {/*  seriesLabel="ToolTip"*/}
        {/*  isExpanded={expandedPanel === 'toolTip'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('toolTip')}*/}
        {/*>*/}
        {/*  <h1>toolTip</h1>*/}
        {/*</BaseAccordion>*/}
        {/*<BaseAccordion*/}
        {/*  seriesLabel="Legend"*/}
        {/*  isExpanded={expandedPanel === 'legend'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('legend')}*/}
        {/*>*/}
        {/*  <h1>legend</h1>*/}
        {/*</BaseAccordion>*/}
        {/*<BaseAccordion*/}
        {/*  seriesLabel="Data labels"*/}
        {/*  isExpanded={expandedPanel === 'data-labels'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('data-labels')}*/}
        {/*>*/}
        {/*  <h1>Data labels</h1>*/}
        {/*</BaseAccordion>*/}
      </Grid>
    </Grid>
  )
};
