import React, { useMemo } from "react";
// import { useSelector } from 'react-redux'

import {parseDateToLocalFormat} from "shared/utilities/dateHelpers";
import {getRandomColor} from "shared/utilities/common";
import NonMarketHighChart from "views/product/details/chart/NonMarketHighChart";

function LineChart(props) {
  const { data: initialData, xAxis, xAxisType, yAxisFields, chartType } = props


  const data = useMemo(() => {
    if(xAxisType === 'date'){
      return initialData.sort((a, b) => {
        if (a[xAxis] && b[xAxis]) {
          return new Date(a[xAxis]).getTime() - new Date(b[xAxis]).getTime()
        }
        return 1;
      })
    }
    return initialData
  }, [
    initialData,
    xAxis,
    xAxisType
  ])

  const categories = useMemo(() => {
    if(xAxisType === 'date'){
      return data.map(item => parseDateToLocalFormat(item[xAxis]))
    }
    return data.map(item => item[xAxis])
  }, [
    data,
    xAxis,
    xAxisType
  ])
  console.log(xAxisType, categories,  'categories')

  const chatMinWidth = useMemo(() =>{
    if(data.length > 100){
      return {
        minWidth: 1900,
        scrollPositionX: 1
      }
    }
    return {}
  }, [
    data
  ])

  const series = useMemo(() => {
    const duelSeries = {}
    if (!(xAxis && yAxisFields && yAxisFields.length > 0)) return [{}]
    if (yAxisFields?.length > 0) {
        yAxisFields.map((chartLine) => {
          if(chartLine?.value){
            duelSeries[chartLine.value] = {
              name: chartLine.value.split('_').join(' ').toUpperCase(),
              color: getRandomColor(),
              data: data.map(item => item[chartLine.value])
            }
          }
          // const chartLine = chartLinesData?.filter(item => item.id === chartLineId)[0]
          // if (chartLine) {
          //   if (item[chartLineId]) {
          //     if (duelSeries[chartLineId] === undefined) {
          //       duelSeries[chartLineId] = {
          //         name: chartLine.label.toUpperCase(),
          //         color: getRandomColor(),
          //         data: []
          //       }
          //     }
          //     const duelPoint = [_dateVal, isNaN(item[chartLineId]) ? 0 : item[chartLineId]]
          //     duelSeries[chartLineId].data.push(duelPoint)
          //   }
          // }
        })
    }
    // console.log(duelSeries, 'duelSeries')
    // console.log(xAxis, yAxisFields, 'yAxisFields')
    return Object.values(duelSeries)

  }, [
    categories,
    data,
    yAxisFields,
    xAxis,
  ])
  const chartConfig = useMemo(() => {
    return {
      chart: {
        type: chartType,
        zoomType: 'x',
        scrollablePlotArea: chatMinWidth,
      },
      xAxis: {
        categories: categories,
        // crosshair: true,
        // type: "datetime",
        // labels: {
        //   formatter: function () {
        //     return Highcharts.dateFormat('%b/%e/%Y', this.value);
        //   }
        // }
      },
      yAxis: {
        min: 0,
      },
      stockTools: {
        gui: {
          enabled: false
        }
      },
      plotOptions: {
        line: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: series
    }
  }, [categories, series, chatMinWidth, chartType])

  return (
    <NonMarketHighChart options={chartConfig}/>
  )
}

export default LineChart