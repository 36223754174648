/* eslint no-restricted-globals:0 */

import React from "react"
import { useSelector, useDispatch } from "react-redux"

import {DrawerLayout, DrawerBody, DrawLayoutBody, DrawerHeader} from '../details/DrawerLayout'

import { Box } from "@mui/system"
import { DataPanel } from "./data-panel";
import DataTableView from "../details/data/Table";
import {useProductContext} from "providers/product/ProductProvider";
import {SidebarHeader} from "../details/SidebarHeader";
import {ChartPanel} from "./ChartPanel";
import ChartContainer from "./chart/ChartContainer";
import Alert from "@mui/lab/Alert";
import {setProductOpt} from "store/slices/StockProductSlice";

const NonMarket = () => {
    const { identifier, data } = useProductContext();
    const dispatch = useDispatch()

    const {
        selectedDataOpt,
        viewMode,
        loadedProductData,
        loadingStockData,
        showSidebar
    } = useSelector(state => state.productData)

    const onLayoutChange = (value) => {
      dispatch(setProductOpt({ path: 'showSidebar', value }))
    }

    return (
        <DrawerLayout
          loading={loadingStockData}
          open={showSidebar}
          onSidebarChange={onLayoutChange}
        >
            <DrawerHeader>
              <SidebarHeader />
            </DrawerHeader>
            <DrawerBody>
                {
                    viewMode === 'datatable' ?
                        <DataPanel /> : <ChartPanel />
                }
            </DrawerBody>
            <DrawLayoutBody>
                {
                    data.length > 0 ? (
                        viewMode === 'datatable' ?
                            <Box id={loadedProductData[identifier] ? identifier : ''} sx={{ display: 'flex', flexGrow: 1, height: '100%' }}>
                                <DataTableView
                                  blob={selectedDataOpt}
                                  data={loadedProductData[identifier] || []}
                                  identifier={identifier}
                                />
                            </Box> :
                            <ChartContainer />
                    ) : <Alert severity="error" style={{ marginTop: 40 }}>There is no remote file available for the current product. Please check the file location</Alert>
                }
            </DrawLayoutBody>
      </DrawerLayout>
    )
}
export default NonMarket
