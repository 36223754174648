import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import {DropdownSelect} from "components/common/Product/DropdownSelect";
import {
    Box,
    IconButton
} from "@mui/material";
import {useSelector, useDispatch} from "react-redux";
import {deleteSeries} from "../../../../store/slices/StockProductSlice";

interface SeriesItem {
    id: any;
    lower: string;
    higher: string
}

interface RangeSeriesAccordionProps {
    isExpanded?: boolean;
    seriesItem: SeriesItem;
    seriesLabel: string;
    onUpdateSerie: (field: string, value: string) => void;
    onChange?: () => void
}

const RangeSeriesAccordion: React.FC<RangeSeriesAccordionProps> = ({
   isExpanded = false,
   seriesItem = {},
   seriesLabel = '',
   onUpdateSerie,
   onChange = () => {},
   ...rest
}) => {
    const dispatch = useDispatch()
    const yAxisFields = useSelector((state: any) => state.productData.yAxisFields)
    return (
        <div {...rest}>
            <Accordion expanded={isExpanded} onChange={onChange} style={{ padding: 0 }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                >
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>{ seriesLabel }</span>
                        <Box display={"flex"} sx={{ gap: 1 }}>
                            <span style={{ fontSize: '11px', fontWeight: 'bold', textTransform: 'capitalize' }}>{ seriesItem?.lower?.split('_').join(" ") }</span>
                            <span style={{ fontSize: '11px', fontWeight: 'bold', textTransform: 'capitalize' }}>{ seriesItem?.higher?.split('_').join(" ") }</span>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <DropdownSelect
                        selectedValue={seriesItem?.lower}
                        onChange={(val: string) => onUpdateSerie('lower', val)}
                        options={yAxisFields}
                        placeholder={'Lower'}
                    />
                    <DropdownSelect
                        selectedValue={seriesItem?.higher}
                        onChange={(val: string) => onUpdateSerie('higher', val)}
                        options={yAxisFields}
                        placeholder={'Higher'}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <IconButton aria-label="delete" onClick={() => dispatch(deleteSeries(seriesItem.id))}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default RangeSeriesAccordion;
