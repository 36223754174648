import React, { useMemo } from "react";
import {  useSelector } from "react-redux";
import {makeStyles} from "@mui/styles";
import Alert from '@mui/lab/Alert';

import {useProductContext} from "../../../../providers/product/ProductProvider";
import DefaultChart from "./chart-types/DefaultChart";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: '10px'
  }
}));

function ChartContainer() {
  const classes = useStyles();
  const { feedSource, databaseCode, productCode, data } = useProductContext()
  const {
    xAxis,
    series,
    marketChartType: chartType,
    xAxisFields
  } = useSelector(state => state.productData)

  const validatedXAxis = useMemo(() => {
    const field = xAxisFields.filter(item => item.id === xAxis)[0]
    if(field){
      if(['date', 'datenumber'].indexOf(field.type) < 0){
        return xAxisFields[0]?.id
      }
      return xAxis
    }else{
      return xAxisFields[0]?.id
    }
  }, [
    xAxis, xAxisFields
  ])
  if(!(chartType && xAxis && series.length > 0)){
    return <Alert severity="info">Please check Values of Object, Chart Type and Series in the Data Panel!</Alert>
  }

  return (
    <div className={classes.root}>
      <DefaultChart
        data={data}
        path={`${feedSource}/${databaseCode}/${productCode}`}
        xAxis={validatedXAxis}
        chartType={chartType}
        yAxisFields={series}
      />
    </div>
  );
}

export default  ChartContainer
