import React, { useMemo } from "react";
import {  useSelector } from "react-redux";
import {makeStyles} from "@mui/styles";
import Alert from '@mui/lab/Alert';

import {useProductContext} from "../../../../providers/product/ProductProvider";
import LineChart from "./chart-types/LineChart";
import PieChart from "./chart-types/PieChart";

const useStyles = makeStyles(() => ({
  root: {
    // flexGrow: 1,
    height: '100%',
    padding: '10px'
  }
}));

function ChartContainer() {
  const classes = useStyles();
  const { feedSource, databaseCode, productCode, data } = useProductContext()
  const { xAxis, series, nonMarketChartType: chartType, fields, categoryField } = useSelector(state => state.productData)

  const xAxisType = useMemo(() => {
    const xAxisField = fields.filter(item => item.id === xAxis)[0]
    if(xAxisField){
      return xAxisField?.type
    }
    return null
  }, [
    fields,
    xAxis
  ])
  const alertMessage = useMemo(() => {
    if(chartType === 'pie'){
      if(!(chartType && xAxis && series.length > 0 && categoryField)){
        return 'Please check CategoryField, XAxis, Value and Series object in the Data Panel!'
      }
    }else{
      if(!(chartType && xAxis && series.length > 0)){
        return 'Please check Value and Series object in the Data Panel!'
      }
    }
    return ''
  }, [
    chartType, xAxis, series, categoryField
  ])

  if(alertMessage !== ''){
    return <Alert severity="info">{alertMessage}</Alert>
  }

  return (
    <div className={classes.root}>
      {
        chartType === 'pie' ? (
          <PieChart
            data={data}
            path={`${feedSource}/${databaseCode}/${productCode}`}
            xAxis={xAxis}
            xAxisType={xAxisType}
            yAxisFields={series}
            chartType={chartType}
            categoryField={categoryField}
          />
        ) : (
          <LineChart
            data={data}
            path={`${feedSource}/${databaseCode}/${productCode}`}
            xAxis={xAxis}
            xAxisType={xAxisType}
            yAxisFields={series}
            chartType={chartType}
          />
        )
      }
    </div>
  );
}

export default  ChartContainer
