import React, {useMemo, useState} from "react";

import {FormGroup, Box, FormControlLabel} from "@mui/material";
import {BpCheckbox} from "views/product/details/BpCheckbox";
import NonMarketHighChart from "views/product/details/chart/NonMarketHighChart";

function PieChart(props) {
  const { data: initialData, xAxis, xAxisType, yAxisFields, chartType, categoryField } = props
  const [selection, setSelection] = useState(null)

  const data = useMemo(() => {
    let innerData = initialData
    if(['date', 'datenumber'].includes(xAxisType)){
      innerData = initialData.sort((a, b) => {
        if (a[xAxis] && b[xAxis]) {
          return new Date(a[xAxis]).getTime() - new Date(b[xAxis]).getTime()
        }
        return 1;
      })
    }
    if(selection){
      innerData = innerData.filter(item => {
        const itemYear = new Date(item[xAxis]).getFullYear().toString();
        return itemYear === selection;
      });
    }
    return innerData
  }, [
    initialData,
    xAxis,
    xAxisType,
    selection
  ])
  // const years = useMemo(() => {
  //   if(xAxis && ['date', 'datenumber'].includes(xAxisType)){
  //     const tempYears = data.reduce((a, b) => {
  //       const year = new Date(b[xAxis]).getFullYear()
  //       if(!a[year]) {
  //         a[year] = 0
  //       }
  //       a[year] += 1
  //       return a
  //     }, {})
  //     return Object.keys(tempYears).filter(key => tempYears[key] > 1);
  //   }
  //   return []
  // }, [
  //   initialData,
  //   xAxis,
  //   categoryField
  // ])
  const years = useMemo(() => {
    if(xAxis && ['date', 'datenumber'].includes(xAxisType)){
      return data.reduce((a, b) => {
        const year = new Date(b[xAxis]).getFullYear()
        if (a.indexOf(year) < 0) a.push(year)
        return a
      }, [])
    }
    return []
  }, [
    initialData,
    xAxis
  ])

  // useEffect(() => {
  //   const categories = data.reduce((a, b) => {
  //     if (a.indexOf(b[categoryField]) < 0) a.push(b[categoryField])
  //     return a
  //   }, [])
  //   console.log(categories, 'categories')
  //   if(categories.length > 100){
  //     setSelection('top-30')
  //   }
  // }, [data, categoryField]);


  const series = useMemo(() => {
    const seriesData = {}
    console.log(categoryField, 'categoryField')
    if (!(categoryField && yAxisFields && yAxisFields.length > 0)) return [{}]
    const yAxis = yAxisFields[0].value


    data.map(item => {
      if(item[categoryField]){
        let serieKey = (item[categoryField]).toString()
        if(serieKey){
          if(!seriesData[serieKey]){
            seriesData[serieKey] = {
              name: serieKey.split('_').join(' ').toUpperCase(),
              // color: getRandomColor(),
              y: 0,
            }
          }
          seriesData[serieKey].y += (item[yAxis] * 1);
        }
      }
    })

    return [{
      name: yAxis.split('_').join(' ').toUpperCase(),
      colorByPoint: true,
      data: Object.values(seriesData).filter(item => item.y > 0).slice(0, 100)
    }]

  }, [
    data,
    yAxisFields,
    categoryField,
  ])
  console.log(series, 'series')

  const chartConfig = useMemo(() => {
    return {
      chart: {
        type: chartType,
      },
      tooltip: {
        valueSuffix: ''
      },
      stockTools: {
        gui: {
          enabled: false
        }
      },
      plotOptions: {
        series: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: [{
            enabled: true,
            distance: 20
          }, {
            enabled: true,
            distance: -40,
            format: '{point.percentage:.1f}',
            style: {
              fontSize: '1.2em',
              textOutline: 'none',
              opacity: 0.7
            },
            filter: {
              operator: '>',
              property: 'percentage',
              value: 10
            }
          }]
        }
      },
      series: series
    }
  }, [series, chartType])

  const handleChange = (event) => {
    console.log(event.target.checked, event.target.name)
    if(event.target.checked){
      setSelection(event.target.name)
    }else{
      setSelection(null)
    }
  }


  console.log(years, 'series')
  return (
    <Box style={{ width: '100%', height: '100%', position: 'relative' }}>
      {
        years.length > 0 && (
          <FormGroup style={{ position: 'absolute', top: '40px', zIndex: 1 }}>
            {
              years.map((item, idx) => (
                <FormControlLabel sx={{ margin: 0 }} className='test-form-control' key={idx} control={<BpCheckbox sx={{ padding: '4px 6px' }} name={item.toString()} onChange={handleChange} size="small" checked={item.toString() === selection} />} label={item} />
              ))
            }
          </FormGroup>
        )
      }
      <NonMarketHighChart options={chartConfig}/>
    </Box>
  )
}

export default PieChart