import React, {ReactNode} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Box,
} from "@mui/material";

interface SeriesAccordionProps {
    isExpanded?: boolean;
    seriesLabel: string;
    seriesSubLabel?: string;
    onChange?: () => void;
    children?: ReactNode;
}


const BaseAccordion: React.FC<SeriesAccordionProps> = ({
     isExpanded = false,
     seriesLabel = '',
     seriesSubLabel = '',
     onChange = () => {},
     children,
     ...rest
}) => {
    return (
        <div {...rest}>
            <Accordion expanded={isExpanded} onChange={onChange} style={{ padding: 0 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>{ seriesLabel }</span>
                        {
                            seriesSubLabel !== '' && (
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textTransform: 'capitalize' }}>{ seriesSubLabel}</span>
                            )
                        }
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {children}
                </AccordionDetails>
          </Accordion>
        </div>
    );
}

export default BaseAccordion;
