import React from "react";

import {makeStyles} from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',
    marginRight: '3px',
  },
  Label: {
    marginBottom: '3px',
    fontSize: '.75rem'
  },
  Input: {
    color: '#849095',
    padding: '0.4rem',
    fontFamily: 'Poppins',
      // textTransform: 'uppercase',
      // cursor: 'pointer',
    background: 'transparent',
    width: '100%',
    borderRadius: '4px !important',
    backgroundColor: 'rgb(255, 255, 255)',
    border: '1px solid #d8dde6',
    marginBottom: '10px',
    fontSize: '13px',
    lineHeight: '1.25rem',
      '&:focus': {
        outline: 'none',
        boxShadow: '0 0 5px -1px #3d91ff'
      },
      '&.custom-form-small': {
        padding: '3px',
        fontSize: '13px'
      }
  },
}));

export default function CustomFormInput(_props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { label, value, fieldName, name, select, options, placeholder = '', className = '' } = _props
  // Default values initialization
  let disabled = false;
  let size = _props.size || ''
  let inputType = _props.type || 'text'

  let labelText = _props.label;

  const renderOpt = (_option) => {
    if (typeof _option === 'object') {
      return <option value={_option.value}>{_option.label}</option>
    } else {
      return <option value={_option}>{_option}</option>
    }
  }
  const handleChange = (e) => {
    _props.onChange(fieldName ? fieldName : 'field', e.target.value)
  }
  return (
    <>
      <div className={`${classes.root} ${className}`}>
        {
          label && label !== '' &&
          <label className={classes.Label}>{label}</label>
        }
        {
          select ? (
            <select
              className={classes.Input + ` custom-form-${size}`}
              name='name'
              value={value}
              onChange={handleChange}
            >
              <option value={''}></option>
              {
                options?.map((item, idx) => (
                  <option value={item.value} key={idx}>{item.label}</option>
                ))
              }
            </select>
          ) : inputType === 'textarea' ? (
            <textarea
              className={classes.Input + ` custom-form-${size}`}
              name='name'
              defaultValue={value}
              onChange={handleChange}
              placeholder={placeholder}
              rows={3}
            />
          ) : (
            <input
              type={inputType}
              className={classes.Input + ` custom-form-${size}`}
              name='name' defaultValue={value}
              onChange={handleChange}
              placeholder={placeholder}
            />
          )
        }
      </div>
    </>
  );
}